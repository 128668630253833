.close_modal {
  margin-left: auto;
  float: right;
  margin-top: -5px;

  .close_modal_button {
    cursor: pointer;
    border: 0;
    width: 16px;
    height: 16px;
    background-image: url('../../images/icons/close_icon.svg');
    background-size: 14px 14px;
    background-position: center;
    background-repeat: no-repeat;
    background-color: transparent;
    border: none;
  }

  @media (prefers-color-scheme: dark) {
    .close_modal_button {
      background-image: url('../../images/icons/close_icon_dark.svg');
    }
  }
}

.ui.modal {
  a {
    font-size: 12px;

    &:hover {
      text-decoration: underline;
    }
  }

  a {
    background: 0 0;
    text-decoration: none !important;
    font-size: 14px;
    font-weight: 400;

    &:hover {
      text-decoration: underline;
    }

    &.no-left-margin {
      margin-left: 0px;
    }
  }
}

@media only screen and (max-width: 767px) {
  .ui.modal > .actions {
    padding: 10px 0 !important;
  }
}

.modal-buttons {
  text-align: right;
  margin-top: 20px;

  .ui.button {
    margin: 0 10px;
  }
}
