.teamspaceSelection {
  margin-bottom: 30px;
  background-color: var(--light-grey);
  padding: 10px;
  border-radius: 10px;
}
.loader {
  display: flex;
  align-items: center;
  justify-content: center;
}
