/*
fake a disabled button.
if we really disable the button, the popup doesn't work
*/
.icon {
  background-color: #c6c6c6;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &:not(:last-child) {
    margin-right: 20px;
  }

  svg {
    fill: #fff;
  }
}

.meetingToolbar {
  display: flex;
  justify-content: center;
}

.button {
  cursor: pointer;
  &:hover {
    filter: brightness(0.9);
  }
  &:active {
    filter: brightness(0.8);
  }
}

.green {
  background-color: var(--weteam-wood-green);
}

.red {
  background-color: #db2828;
}

.meetingStatusIcon {
  display: inline-block;
  margin-top: 4px;
}

.showHideMeetingIcon {
  line-height: 0.9em;
  font-size: 1.4em !important;
  color: #fff;
  margin: 0 !important;
  margin-top: 3px !important;
}

.hangUpIcon {
  fill: #fff;
}
